import React from 'react';
import { PageProps } from 'gatsby';
import { Locale } from 'features/localization';
import { PplPage } from 'features/question-banks';

function Page({ pageContext: { locale } }: PageProps<unknown, { locale: Locale }>) {
  return <PplPage locale={locale} />;
}

export default Page;
